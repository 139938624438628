import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { Container, Button, Typography, TextField, Paper, Checkbox, Card, Snackbar, Divider, makeStyles, CardContent } from "@material-ui/core";
import gql from 'graphql-tag';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import MuiAlert from '@material-ui/lab/Alert';
import Axios from "axios";
import { getBaseURL } from "../../src/baseURL";
import { useSelector } from "react-redux";

export const AccessRequest = ({ email }) => {
    const GET_COMPANIES = gql`
        query GetCompanies {
            company {
                name
                id
                state
            }
        }
    `;
    const GET_ACCESS_REQUESTS = gql`
    query GetAccessRequests($id: Int, $state: Boolean) {
        accessRequests(id: $id, state: $state) {
            id,
            reasonForRequest,
            requestDate,
            user {
                email
            },
            requestedCompanies {
                id,
                name,
                state
            }
        }
    }
`;
    const ADD_ACCESS_REQUEST = gql`
        mutation AddAccessRequest($reasonForRequest: String!, $requestedCompanies: [Int]!) {
            addAccessRequest(reasonForRequest: $reasonForRequest, requestedCompanies: $requestedCompanies) {
                id
            }
        }
    `;

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        },
        fileCard: {
            width: '325px',
            height: '50px',
            display: 'inline-block',
            marginRight: '15px',
            marginBottom: '15px',
            '&:hover': {
                border: `1px solid ${theme.palette.primary.main}`,
                cursor: 'pointer'
            }
        }
    }));


    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorSnackBarOpen(false);

    }

    const classes = useStyles();
    const [accessRequests, setAccessRequests] = useState();
    const { data: companies } = useQuery(GET_COMPANIES);
    const [reasonForRequest, setReasonForRequest] = useState("");
    const [requestedCompanies, setRequestedCompanies] = useState([]);
    const [requestedCompaniesNames, SetRequestedCompaniesNames] = useState([]);
    const [accessRequestSubmitted, setAccessRequestSubmitted] = useState(false);
    const [addAccessRequestMutation] = useMutation(ADD_ACCESS_REQUEST);
    const [missingRequestReason, setMissingRequestReason] = useState(false);
    const [missingCompany, setMissingCompany] = useState('');
    const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
    const [errorSnackBarText, setErrorSnackBarText] = useState("");
    const [open, setOpen] = useState(false);
    const currentUser = useSelector(state => state.auth);

    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken },
        timeout: 24000000
    };

    const onAccessRequestSumbit = () => {
        if (reasonForRequest && requestedCompanies.length > 0) {
            const formData = {
                reasonForRequest: reasonForRequest,
                requestedCompanies: requestedCompanies,
            }
            if (accessRequests.length > 0) {
                const userActiveAccessRequests = accessRequests.filter(x => x.user.email === email);
                var accessrequested = [];
                userActiveAccessRequests.map(x => x.requestedCompanies).filter(function (company) {
                    if (accessrequested.length > 0)
                        return;
                    if (company.length > 1) {
                        company.filter(function (companyname) {
                            if (requestedCompaniesNames.includes(companyname.name)) {
                                accessrequested.push(requestedCompaniesNames);
                            }
                        })
                    } else {
                        if (company.length > 0) {
                            if (requestedCompaniesNames.includes(company[0].name)) {
                                accessrequested.push(requestedCompaniesNames);
                            }
                        }
                    }
                });
                if (accessrequested.length > 0) {
                    setErrorSnackBarText("You have already requested access for this company");
                    //setErrorSnackBarOpen(true);
                    setOpen(true);
                } else {
                    //setErrorSnackBarOpen(false);

                    //addAccessRequestMutation({ variables: { reasonForRequest: reasonForRequest, requestedCompanies: requestedCompanies } }).then(r => setAccessRequestSubmitted(true))

                    Axios.post(`${getBaseURL()}files/accessManageRequest`, formData, config)
                        .then((response) => {
                            if (response.status === 200) {
                                setErrorSnackBarText("Data Inserted Successfully");
                                setOpen(true);
                            } else {
                                setErrorSnackBarText("Error", error);
                                setOpen(false);
                                console.error("An error occurred:", error);
                            }
                        })
                        .catch((error) => {
                            setErrorSnackBarText("Error", error);
                            console.error("An error occurred:", error);
                            setOpen(false);
                        })
                    setErrorSnackBarText("Error", error);
                    console.error("An error occurred:", error);
                    setOpen(false);
                }
            } else
                // addAccessRequestMutation({ variables: { reasonForRequest: reasonForRequest, requestedCompanies: requestedCompanies } }).then(r => setAccessRequestSubmitted(true))

                Axios.post(`${getBaseURL()}files/accessManageRequest`, formData, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setOpen(true);
                        } else {
                            setOpen(false);
                            console.error("An error occurred:", error);
                        }
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                        setOpen(false);
                    })
            setErrorSnackBarText("Error", error);
            console.error("An error occurred:", error);
            setOpen(false);
        }
        else if (reasonForRequest === "") {
            setMissingRequestReason(true);
        }
        else if (requestedCompanies.length === 0) {
            setMissingCompany("Please Select Companies");
        }
        setErrorSnackBarText("Data Inserted Successfully");
        setOpen(true);
    }

    // const SnackAlert = (props) => {
    //     return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }
    const { loading, error, refetch } = useQuery(GET_ACCESS_REQUESTS, { onCompleted: data => { setAccessRequests(data) } });
    const handleAutoComplete = (event, value) => {

        var selectedCompanyIDs = [];
        var selectcompanyname = [];
        value.forEach(x => selectedCompanyIDs.push(x.id));
        value.forEach(x => selectcompanyname.push(x.name));
        setRequestedCompanies(selectedCompanyIDs);
        SetRequestedCompaniesNames(selectcompanyname);
        setMissingCompany("");
        console.log(selectedCompanyIDs);


    }
    useEffect(() => {
        //refetch(GET_ACCESS_REQUESTS);
        handleDownloadClick();
    }, []);

    const handleDownloadClick = () => {
        // const formData = {
        //     Id: "",
        //     State: state,
        // }
        Axios.get(`${getBaseURL()}files/getAccessRequest`, config)
            .then((response) => {

                setAccessRequests(response.data)

            })
            .catch((error) => {
                console.error("An error occurred:", error);
            })

    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    }
    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <Typography variant="h4">Access Request</Typography>

            <Paper className={classes.root}>
                {accessRequestSubmitted ?
                    <Alert severity="success">Access request submitted!</Alert>
                    :
                    <React.Fragment>
                        <TextField
                            color="primary"
                            variant="outlined"
                            label="Reason For Request"
                            fullWidth
                            onChange={(e) => { setReasonForRequest(e.target.value); if (e.target.value) { setMissingRequestReason(false); } }}
                            required
                            helperText={missingRequestReason ? "Required" : ""}
                            error={missingRequestReason}
                        />

                        {companies &&
                            <Autocomplete
                                style={{ marginTop: '20px', marginBottom: '10px' }}
                                multiple
                                options={companies.company}
                                getOptionLabel={(option) => option.name}
                                onChange={handleAutoComplete}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Companies"
                                        placeholder="Company Name"
                                        error={missingCompany.length > 0}
                                        helperText={missingCompany}
                                    />
                                )}
                            />
                        }

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onAccessRequestSumbit()}
                        >Submit</Button>
                    </React.Fragment>
                }
            </Paper>
            <Snackbar
                open={errorSnackBarOpen}
                autoHideDuration={5000}
                onClose={handleErrorSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                {/* <SnackAlert onClose={handleErrorSnackbarClose} severity="error">
                    {errorSnackBarText}
                </SnackAlert> */}
            </Snackbar>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={errorSnackBarText}
            >
            </Snackbar>
        </Container>
    )
}