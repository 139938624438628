import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useHistory } from 'react-router-dom';
import store from "./store";
import { Provider } from "react-redux";
import { authProvider } from './authProvider';
import Layout from './components/Layout/Layout';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from './apolloClient';
import { Files } from './components/miscellaneous/Files';
import { ManageUsers } from './components/admin/ManageUsers';
import { ModerateFiles } from './components/admin/ModerateFiles';
import { ManageCompanies } from './components/admin/ManageCompanies';
import { AccessRequest } from './components/AccessRequest';
import { ManageAccessRequests } from './components/admin/ManageAccessRequests';
import { ExternalUserRequest } from './components/ExternalUserRequest';
import { ManageExternalUserRequests } from './components/admin/ManageExternalUserRequests';
import { ManageNewCompanyRequests } from './components/admin/ManageNewCompanyRequests';
import { SplashPage } from './components/SplashPage/SplashPage';
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsalAuthentication, useMsal } from "@azure/msal-react";
import { CustomNavigationClient } from './NavigationClient';
import { useSelector } from "react-redux";

const App = ({ pca }) => {
    const [apiProgress, setApiProgress] = useState("");

    const FileUploadApInProgress = (apiStatus) => { // the callback. Use a better name
        setApiProgress(apiStatus);
    };

    return (
        <Provider store={store}>
            <MsalProvider instance={pca}>
                <AppComponent />
            </MsalProvider>
        </Provider>
    );
}

export default App;

function AppComponent() {
    const isAuthenticated = useIsAuthenticated();
    const { error } = useMsalAuthentication(InteractionType.Redirect);

    const { instance, accounts, inProgress } = useMsal();

    const [accessToken, setAccessToken] = useState();

    useEffect(() => {
        if (inProgress === "none" && accounts.length > 0) {
            console.log("Getting access token");
            // Retrieve an access token
            instance.acquireTokenSilent({
                account: accounts[0],
                scopes: ["User.Read"]
            }).then(response => {
                if (response.accessToken) {
                    console.log("got access token");
                    setAccessToken(response.accessToken);
                } else {
                    console.log("failed to get access token");
                }
            });
        }
    }, [inProgress, accounts, instance]);

    const currentUser = useSelector(state => state.auth);

    useEffect(() => {
        console.log("app component init");
    }, []);

    if (isAuthenticated) {
        return (
            (currentUser.jwtIdToken && currentUser.jwtIdToken.idToken) ?
                <BrowserRouter>
                    <ApolloProvider client={GraphQlClient(currentUser.jwtIdToken.idToken)}>
                        <Layout>
                            <Routes>
                                <Route exact path="/splashpage" element={<SplashPage />} />
                                <Route exact path="/" element={<Files />} />
                                <Route exact path="/files" element={<Files />} />
                                <Route exact path="/manageusers" element={<ManageUsers />} />
                                <Route exact path="/moderatefiles" element={<ModerateFiles />} />
                                <Route exact path="/managecompanies" element={<ManageCompanies />} />
                                <Route exact path="/accessrequest" element={<AccessRequest email={currentUser.user.email} />} />
                                <Route exact path="/manageaccessrequests" element={<ManageAccessRequests />} />
                                <Route exact path="/addexternaluser" element={<ExternalUserRequest />} />
                                <Route exact path="/manageexternaluserrequests" element={<ManageExternalUserRequests />} />
                                <Route exact path="/managenewcompanyrequests" element={<ManageNewCompanyRequests />} />
                            </Routes>
                        </Layout>
                    </ApolloProvider>
                </BrowserRouter>
                :
                <p>Loading. Please wait</p>
        );
    }
    else if (error) {
        return <span>An error occurred during login</span>
    }
    else {
        return <span>Please wait. Redirecting to login.</span>
    }
}