import React, { useState, useEffect } from "react";
import { Container, Button, Typography, TextField, Checkbox, Grid, CircularProgress } from "@material-ui/core";
import gql from 'graphql-tag';
import { useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMutation, useQuery } from '@apollo/client';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as lodash from 'lodash';
import Axios from 'axios';
import { getBaseURL } from "../../baseURL";

export const ManageCompanies = () => {

    const ADD_New_COMPANY_Request = gql`
    mutation AddNewCompanyRequest($companyName: String!,$reason: String!) {
        addNewCompanyRequest(requestedCompany: $companyName,reason:$reason) {
            id
        }
    }
`;

    const GET_COMPANIES = gql`
        query GetCompanies {
            company {
                name
                id
                state
            }
        }
    `;
    const GET_Requested_COMPANIES = gql`
    query GetRequestedCompanies {
        requestedCompany {
            id
            requestedCompany            
            reason
            requestedBy
            requestedDate
        }
    }
`;
    const UPDATE_COMPANY = gql`
        mutation UpdateCompany($companyId: Int!, $state: Boolean) {
            updateCompany(companyId: $companyId, state: $state) {
                id
            }
        }
    `;
    const DELETE_COMPANY = gql`
    mutation DeleteCompany($companyId: Int!) {
        deleteCompany(companyId: $companyId) {
           id
        }
    }
`;

    const currentUser = useSelector(state => state.auth);
    const [addCompanyOpen, setAddCompanyOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addCompanyMutation, { error: addCompanyErrors }] = useMutation(ADD_New_COMPANY_Request);
    const [companyName, setCompanyName] = useState("");
    const [reason, setReason] = useState("");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const { error, refetch, data } = useQuery(GET_COMPANIES);
    const [RequestedNewCompanies, setRequestedNewCompanies] = useState([]);
    const { requestedNewCompanies } = useQuery(GET_Requested_COMPANIES, {
        onCompleted: data => {

            setRequestedNewCompanies(data.requestedcompany)
        }
    });
    const [addCompanyError, setAddCompanyError] = useState("");
    const [addReasonError, setAddReasonError] = useState("");
    const [updateCompanyMutation] = useMutation(UPDATE_COMPANY);
    const [deleteCompanyMutation] = useMutation(DELETE_COMPANY);
    const [companies, setCompanies] = useState([]);
    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken },
        timeout: 24000000
    };


    const handleDownloadClick = () => {
        if (companyName === "") {
            setAddCompanyError("Company Name is mandatory");
            return;
        }
        if (reason === "") {
            setAddReasonError("Reason is mandatory");
            return;
        }
        const companyExists = companies.some(company => company.name.toLowerCase() === companyName.toLowerCase());
        if (companyExists) {
            setAddCompanyError("Company Already Exists");
            return;
        }
        const formData = {
            CompanyName: companyName,
            Reason: reason,
        }
        Axios.post(`${getBaseURL()}files/addNewCompany`, formData, config)
            .then((response) => {
                if (response.status === 200) {
                    setCompanyName("");
                    setReason("");
                    setAddCompanyOpen(false);
                    setSnackBarOpen(true);

                } else {
                    console.error("An error occurred:", error);
                    setAddCompanyError("An error occurred");
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                setAddCompanyError("An error occurred");
            })
        setAddCompanyOpen(false);
        setSnackBarOpen(true);
    };

    const onAddCompany = async (event) => {
        event.preventDefault();
        if (companyName === "") {
            setAddCompanyError("Company Name is mandatory");
            return;
        }

        if (reason === "") {
            setAddReasonError("Reason is mandatory");
            return;
        }

        // Check if the company already exists
        const companyExists = companies.some(company => company.name.toLowerCase() === companyName.toLowerCase());
        if (companyExists) {
            setAddCompanyError("Company Already Exists");
            return;
        }

        setLoading(true);
        try {
            // Perform the GraphQL mutation
            await addCompanyMutation({ variables: { companyName: companyName, reason: reason } });
            // Reset form values and close dialog after successful mutation
            setCompanyName("");
            setReason("");
            setAddCompanyOpen(false);
            setSnackBarOpen(true);
        } catch (error) {
            console.error("An error occurred:", error);
            setAddCompanyError("An error occurred");
        } finally {
            setLoading(false);
        }
    }
    // const onAddCompany = async (event) => {
    //     if (companyName !== "" && reason !== "") {
    //         for (let i = 0; i < companies.length; i++) {
    //             if (companies[i].name === companyName.toLowerCase()) {
    //                 setAddCompanyError("Company Already Exists")
    //                 break;
    //             }
    //         }
    //         // if (companies.find(e => e.name.toLowerCase() === companyName.toLowerCase()) || RequestedNewCompanies.find(e => e.requestedCompany.toLowerCase() === companyName.toLowerCase())) {
    //         //     setAddCompanyError("Company Already Exists")
    //         // }
    //         //else {
    //         event.preventDefault();
    //         setLoading(true);
    //         addCompanyMutation({ variables: { companyName: companyName, reason: reason } })
    //             .then(r => {
    //                 setTimeout(() => {
    //                     setAddCompanyOpen(false);
    //                     setSnackBarOpen(true);
    //                     setLoading(false);
    //                 }, 1000);
    //             })
    //             .catch(e => {
    //                 setAddCompanyError("An error occured");
    //                 setLoading(false);
    //             })
    //         //}
    //     }
    //     else if (companyName === "") {
    //         setAddCompanyError("Company Name is mandatory")
    //         setLoading(false);
    //     }
    //     else if (reason === "") {
    //         setAddReasonError("Reason is mandatory")
    //         setLoading(false);
    //     }
    // }

    useEffect(() => {
        if (data && data.company) {
            setCompanies(data.company);
        }
    }, [data])
    useEffect(() => {
        if (data && data.company) {
            setCompanies(data.company);
        }
    }, [data])
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const onCompanyCheckChange = (companyId, checked) => {
        var companiesCopy = lodash.cloneDeep(companies);
        companiesCopy.find(x => x.id === companyId).state = checked;
        setCompanies(companiesCopy);

        updateCompanyMutation({ variables: { companyId: companyId, state: checked } });
    }
    const onRequestAction = (id) => {
        deleteCompanyMutation({ variables: { companyId: id } })
            .then(data => {
                refetch(GET_COMPANIES);
            })
            .catch(e => {
                var companiesCopy = [...companies];
                companiesCopy.find(x => x.id === id).error = true;
                setCompanies(companiesCopy);
            });
    }
    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                <Typography variant="h4" style={{ flex: 1 }}>Manage Companies</Typography>
                <Button variant="contained" color="primary" onClick={() => setAddCompanyOpen(true)}>Add Company</Button>
                {
                    addCompanyOpen && (
                        <Dialog onClose={() => setAddCompanyOpen(false)} open={addCompanyOpen} fullWidth={true} maxWidth={'sm'}>
                            <DialogTitle>Add Company</DialogTitle>
                            <DialogContent>
                                <TextField
                                    color="primary"
                                    variant="outlined"
                                    label="Company Name"
                                    fullWidth
                                    onChange={(e) => { setCompanyName(e.target.value); setAddCompanyError("") }}
                                    error={addCompanyError.length > 0}
                                    helperText={addCompanyError}
                                />

                            </DialogContent>
                            <DialogContent>
                                <TextField
                                    color="primary"
                                    variant="outlined"
                                    label="Reason"
                                    fullWidth
                                    onChange={(e) => { setReason(e.target.value); setAddReasonError("") }}
                                    error={addReasonError.length > 0}
                                    helperText={addReasonError}
                                />

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="Submit Request"
                                    color="primary"
                                    onClick={handleDownloadClick}
                                    disabled={loading}
                                >{loading ? <CircularProgress style={{ margin: 0, padding: 0, marginLeft: '40%', marginBottom: '45%' }} /> : 'Submit Request'}</Button>
                                <Button
                                    label="Cancel"
                                    color="primary"
                                    onClick={() => setAddCompanyOpen(false)}
                                >Cancel</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </Grid>

            {!loading && companies &&
                <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Enabled</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={item.state}
                                                color="primary"
                                                onChange={() => onCompanyCheckChange(item.id, !item.state)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {item.error ?
                                                <Typography variant="subtitle1">AN ERROR OCCURED</Typography>
                                                :
                                                item.deleted ?
                                                    <Typography variant="subtitle1">DELETED</Typography>
                                                    :
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => onRequestAction(item.id)}
                                                    >Delete</Button>}
                                        </TableCell>
                                    </TableRow>

                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            <Snackbar
                open={snackBarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    Request to add {companyName} submitted successfully!
                </Alert>
            </Snackbar>
        </Container>
    );
}