import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import NavMenu from './NavMenu';
import classNames from 'classnames';
// import {  loadTheme } from '@uifabric/styling';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { AccessRequest } from '../AccessRequest'

const drawerWidth = 360;
const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
		fontFamily: "Arial, Helvetica, sans-serif",
	},
	palette: {
		primary: {
			main: "#140a9a"
		},
		secondary: {
			main: "#818386"
		}
	},
	overrides: {
		MuiTableRow: {
			root: { //for the body
				height: "100%"
			},
			head: { //for the head
				height: "100%"
			},
		}
	},
	drawerWidth: drawerWidth,
});

const styles = () => ({
	root: {
		display: 'flex',
		flexGrow: 1,
	},
	main: {
		display: 'block',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: 0,
	},
	contentShift: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: drawerWidth - 150,
	},
	contentPaper: {
		...theme.mixins.gutters(),
		marginTop: 50,
		padding: theme.spacing(2),
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
});

// loadTheme(
// 	{
// 		palette: {
// 			themePrimary: '#140a9a',
// 			themeLighterAlt: '#f2f1fb',
// 			themeLighter: '#cdcbef',
// 			themeLight: '#a5a1e0',
// 			themeTertiary: '#5c54c2',
// 			themeSecondary: '#261da5',
// 			themeDarkAlt: '#11088a',
// 			themeDark: '#0e0774',
// 			themeDarker: '#0b0556',
// 			neutralLighterAlt: '#f3f3f3',
// 			neutralLighter: '#efefef',
// 			neutralLight: '#e5e5e5',
// 			neutralQuaternaryAlt: '#d6d6d6',
// 			neutralQuaternary: '#cccccc',
// 			neutralTertiaryAlt: '#c4c4c4',
// 			neutralTertiary: '#595959',
// 			neutralSecondary: '#373737',
// 			neutralPrimaryAlt: '#2f2f2f',
// 			neutralPrimary: '#000000',
// 			neutralDark: '#151515',
// 			black: '#0b0b0b',
// 			white: '#fafafa',
// 		  }
// 	}
// )

const Layout = ({ children, classes }) => {
	const [isOpen, setOpen] = useState(false);
	const handleSetOpen = (open) => setOpen(open);

	const dispatch = useDispatch();

	const [userAccess, setUserAccess] = useState(false);
	const [loadedUser, setLoadedUser] = useState(false);
	const [loadUserError, setLoadUserError] = useState(false);
	const currentUser = useSelector(state => state.auth);
	const [currentUserWithPermissions, setCurrentUserWithPermissions] = useState(null);

	const GET_USER = gql`
        query GetUser($userId: String) {
            userPermissions(userId: $userId) {
                state
                permission {
                    permissionName
                }
            }
        }
        `;

	const { loading, error, data } = useQuery(GET_USER, { variables: { userId: currentUser.user.guid } });

	useEffect(() => {
		if (!currentUserWithPermissions && data) {
			dispatch({ type: "CURRENT_USER", payload: data });
			setCurrentUserWithPermissions(data.userPermissions);
			setLoadedUser(true);

			if (data.userPermissions != null && data.userPermissions.find(p => p.permission.permissionName === "Access State").state) {
				setUserAccess(true);
			}
		}
	}, [data]);

	return (
		<MuiThemeProvider theme={theme}>
			<span>
				<NavMenu isOpen={isOpen} handleSetOpen={handleSetOpen} currentUser={currentUserWithPermissions} hasAccess={userAccess} />
				{(loadedUser && userAccess) &&
					<React.Fragment>
						<main
							className={classNames(classes.content, classes.main, {
								[classes.contentShift]: isOpen,
							})}
						>
							<div style={{ marginTop: "60px", marginLeft: "0", marginRight: "0" }}>
								{children}
							</div>
						</main>
					</React.Fragment>
				}
				{(loadedUser && !userAccess) &&
					<main
						className={classNames(classes.content, classes.main, {
							[classes.contentShift]: isOpen,
						})}
						style={{ marginTop: '50px' }}
					>
						<AccessRequest />
					</main>
				}
			</span>
		</MuiThemeProvider>
	)
}

export default withStyles(styles, { withTheme: true })(Layout);