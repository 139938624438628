import React, { useState, useEffect } from "react";
import { Button, Typography, TextField, FormGroup, FormControlLabel, Checkbox, Divider, /* Box, */ CircularProgress } from "@material-ui/core";
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as lodash from 'lodash';
import { getBaseURL } from "../../baseURL";
import { useSelector } from "react-redux";
import Axios from 'axios';

export const UserProfile = ({ userId, isOpen, handleSetOpen }) => {
    const [user, setUser] = useState();
    const [userPermissions, setUserPermissions] = useState();
    const [emailAddress, setEmailAddress] = useState("");
    const currentUser = useSelector(state => state.auth);

    const GET_USER = gql`
        query GetUser($userId: String) {
            user(guid: $userId) {
                email
            }
            userPermissions(userId: $userId) {
                state
                permission {
                    id
                    permissionName
                }
            }
            userCompanies(userId: $userId) {
                state
                company {
                    id
                    name
                }
            }
        }
    `;

    const UPDATE_USER_PERMISSION = gql`
        mutation UpdateUserPermission($permissionId: Int!, $userId: String!, $state: Boolean!) {
            updateUserPermission(permissionId: $permissionId, userId: $userId, state: $state) {
                email
            }
        }
    `;

    const UPDATE_USER_COMPANY = gql`
        mutation UpdateUserCompany($companyId: Int!, $userId: String!, $state: Boolean!) {
            updateUserCompany(companyId: $companyId, userId: $userId, state: $state) {
                email
            }
        }
    `;

    const { /* loading, error, refetch, */ data } = useQuery(GET_USER, { variables: { userId: userId } });
    const [updateUserPermissionMutation] = useMutation(UPDATE_USER_PERMISSION);
    const [updateUserCompanyMutation] = useMutation(UPDATE_USER_COMPANY);


    const [userCompanies, setUserCompanies] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);

    const updateUserPermission = (permissionId, checked) => {
        var userPermissionsCopy = lodash.cloneDeep(userPermissions);
        userPermissionsCopy.find(x => x.permission.id === permissionId).state = checked;
        updateUserPermissionMutation({ variables: { permissionId: permissionId, userId: userId, state: checked } });
        setUserPermissions(userPermissionsCopy);
    }

    const updateUserCompany = (companyId, checked) => {
        var userCompaniesCopy = lodash.cloneDeep(allCompanies);
        userCompaniesCopy.find(x => x.id === companyId).state = checked;
        updateUserCompanyMutation({ variables: { companyId: companyId, userId: userId, state: checked } });
        //setUserCompanies(userCompaniesCopy);
        setAllCompanies(userCompaniesCopy);
    }

    // useEffect(() => {
    //     debugger;
    //     if (data) {
    //         setUser(data)
    //         console.log(data);
    //     }
    // }, [data])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken },
            timeout: 24000000
        };
        if (data) {
            setUser(data)
            console.log(data);
        }
        if (user && user.user[0]) {
            setEmailAddress(user.user[0].email);
            setUserCompanies(user.userCompanies);
            setUserPermissions(user.userPermissions);
        }

        Axios.get(`${getBaseURL()}files/getAllCompanies/${userId}`, config)
            .then((response) => {
                if (response !== "") {
                    setAllCompanies(response.data);
                }
            })
            .catch((error) => {
                console.log(error.message);
            })
    }, [data, user]);

    return (
        <Dialog open={isOpen} onClose={() => handleSetOpen(false)} style={{ overflow: 'hidden' }} fullWidth={true} maxWidth={'md'}>
            <DialogTitle><Typography variant="h5">Manage User</Typography></DialogTitle>
            <DialogContent>
                {(user && user.user[0]) ?
                    <React.Fragment>
                        <TextField
                            label="Email Address"
                            value={emailAddress.toLocaleLowerCase()}
                            type="email"
                            style={{ marginRight: '20px', marginTop: '10px' }}
                            fullWidth
                            disabled
                        />

                        <Typography variant="subtitle1" style={{ marginTop: '10px' }}>Permissions</Typography>
                        {userPermissions && userPermissions.map((item, index) => {
                            return (
                                <FormGroup row key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={item.state} value={item.permission.id} color="primary" onChange={() => updateUserPermission(item.permission.id, !item.state)} />
                                        }
                                        label={item.permission.permissionName}
                                    />
                                </FormGroup>
                            );
                        })}
                        <Divider />
                        <Typography variant="subtitle1">User Companies</Typography>
                        {allCompanies && allCompanies.map((item, index) => {
                            return (
                                <FormGroup row key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={item.state}
                                                color="primary"
                                                onChange={() => updateUserCompany(item.id, !item.state)}
                                            />
                                        }
                                        label={item.name}
                                    />
                                </FormGroup>
                            )
                        })}
                    </React.Fragment>
                    :
                    <CircularProgress />
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSetOpen(false)} color="primary">Close</Button>
            </DialogActions>

        </Dialog>
    );
}