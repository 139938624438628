const initialState = {
    user: {}
};

export default function authReducer(state = initialState, action) {
    console.log("in auth reducer", action);
    switch (action.type) {
        case "CURRENT_USER":
            console.log("current user", action);
            return { ...state, user: action.payload }
        case 'AAD_LOGIN_FAILED':
            console.log("longin failed", action);
            return { ...state, aadResponse: null }
        case 'AAD_LOGIN_ERROR':
            console.log("login error", action);
            return { ...state, aadResponse: action.payload }
        case 'AAD_LOGIN_SUCCESS':
            console.log("login success", action);
            return { ...state, aadResponse: action.payload }
        case 'SET_TOKEN':
            console.log("set token case", action);
            return Object.assign({}, state, {
                jwtIdToken: Object.assign({}, state.aadResponse.jwtIdToken, action.payload)
            });
        case 'msal:acquireTokenSuccess':
            console.log("msal:acquireTokenSuccess", action);
            return Object.assign({}, state, {
                jwtIdToken: Object.assign({}, state, action.payload)
            });
        default:
            console.log("default", state);
            return state;
    }
}